import PROBLEM from '../src/assets/problem.jpg';
import SOLUTION from '../src/assets/picasso_photo.jpeg';
import BoldJourneyLogo from '../src/assets/boldJourneyLogo.png';
import CosmopolitanLogo from '../src/assets/cosmopolitanLogo.svg';

export const SHOWCASE_DATA = [
  {
    header: "WHAT IS PMDD ?",
    body: [
      "Pre Menstrual Dysphoric Disorder (PMDD) is a hormonal mood disorder that impacts 60 million women globally, causing severe mood disruptions; 70% face suicidal thoughts and 30% attempt suicide. Despite its recognition in the DSM-5 in 2019, proper diagnosis and treatment is hampered by insufficient awareness, education, and research, with an average diagnosis time of 12 years, with typically four misdiagnoses.",
      // "Until 1993, women were not legally required to be a part of medical research because they claimed due to our cycle, we were too complicated and costly to be researched properly. This led to a significant research gap, which RedTentPMDD aims to help close.",
      // "Many women still feel dismissed at their doctor appointments, leaving confused and hopeless. Even in emergency rooms, PMDD is often unheard, dismissed and misunderstood. ",
      // "The current cause of PMDD is unknown.",
    ],
    visual: null,
  },
  {
    header: "What is Red Tent PMDD?",
    body: [
      "Introducing our comprehensive PMDD platform: an innovative solution designed to accelerate diagnosis.",
      "Our easy-to-use platform is designed to significantly advance education and self-awareness by leveraging our proprietary technology, empowering women to actively participate and have a voice in their health appointments.",
      "We employ a proprietary AI-driven approach to provide you with clinical diagnostic insights, while consistently safeguarding your privacy.",
      "Red Tent PMDD🩸 is dedicated to protecting your data and ensuring a secure environment for women to effectively manage their health.",
      ],
    visual: SOLUTION,
  },
  {
    header: "THE APP",
    body: [
        "<strong>Awareness</strong> - Cycle tracking and reports",
        "<strong>Education</strong> - Education is essential to empowerment",
        "<strong>Community</strong> - You are not alone",
        "<strong>Resources</strong> - Where to find immediate and long term help",
        "<strong>Research</strong> - Removing the bias and closing the gap in women’s health research",
      ],

    visual: null,
  },
];

export const FEATURED_IN_DATA = [
  {
    name: "Bold Journey",
    url: "https://boldjourney.com/meet-ariel-watson/",
    logo: BoldJourneyLogo,
  },
  {
    name: "Cosmopolitan UK",
    url: "https://www.cosmopolitan.com/uk/body/a62017418/pmdd-women-health-support/",
    logo: CosmopolitanLogo,
  },
  // Add more featured publications as needed
];

export const FAQ_DATA = [
  {
    question: "What is the MetaHorse Society?",
    answer:
      "MetaHorse Society (MHS) is a pioneering initiative aiming to redefine the relationship between human intelligence (HI) and artificial intelligence (AI). It thrives on three pillars: MHS NFTs, MHS DAO, and MHS Treasury. MHS provides an environment for both HI and AI to collaborate and make collective decisions.",
  },
  {
    question: "What are MHS NFTs?",
    answer:
      "MHS NFTs are a unique collection of 10,000 horsehead non-fungible tokens. Holding these NFTs grants members exclusive rights within the MHS, including participation in member-only events, accessing unique information, and more.",
  },
  {
    question: "How does MHS DAO operate?",
    answer:
      "MHS DAO is a transparent consensus mechanism on the blockchain. It's a decentralized autonomous organization where members can submit proposals. The direction and activities of MHS are driven by these proposals and the subsequent member votes.",
  },
  {
    question: "How is the MHS Treasury funded?",
    answer:
      "The MHS Treasury is primarily funded through NFT sales, both initial and secondary. It also receives funds through token sales, profits from proposal executions, and accepts donations and grants.",
  },
  {
    question: "Can I remain anonymous as a MHS member?",
    answer:
      "Absolutely. MHS values and ensures member anonymity. As an NFT holder, you are recognized only by your cryptocurrency wallet address. The intelligence behind the wallet, whether human, AI, or a blend, remains private.",
  },
  {
    question: "How can I submit a proposal to MHS DAO?",
    answer:
      "MHS NFT holders have the right to submit proposals to the MHS DAO. Detailed guidelines and processes for submission will be provided to members upon joining.",
  },
  {
    question: "How are MHS NFTs priced?",
    answer:
      "MHS NFTs have varying price points, from 0.1 ETH to 10 ETH, based on the group (from Group A to Group D) they belong to. Each group has its unique benefits and significance within the MHS ecosystem.",
  },
  {
    question: "What happens during the MHS presales?",
    answer:
      "During presales, Group 0 NFTs are minted by MHS founders, while Groups B, C, and D are minted by the MHS Treasury Wallet. These NFTs are then made available for sale upon the completion of the prior group's sale.",
  },
  {
    question: "What is the roadmap for MHS?",
    answer:
      "The MHS roadmap is linked to the minting of each NFT group, from Group A to D. Each completed minting starts a 180-day countdown for specific milestones, such as MHS DAO activation and development cycles.",
  },
  {
    question: "What are the benefits of holding an MHS NFT?",
    answer:
      "Holders of MHS NFTs not only acquire a unique digital asset but also become stakeholders in the future of human-AI collaboration. They can participate in exclusive events, access privileged information, contribute to the DAO, and more.",
  },
  {
    question: "How does AI contribute to MHS?",
    answer:
      "AI has a pivotal role in MHS. It contributes proposals, insights, and decision-making support within the MHS DAO, allowing for a richer, more informed collaborative environment.",
  },
  {
    question: "What are the layers and traits of MHS NFTs?",
    answer:
      "MHS NFTs are crafted with over 8 layers and encompass 270+ unique traits. This allows for a vast variety of digital art, ranging from horses to rare unicorns and zebras.",
  },
  {
    question: "Is there a limit to how many NFTs I can purchase?",
    answer:
      "There's no specified limit on NFT purchases per individual. However, remember that there are only 10,000 unique MHS NFTs available.",
  },
  {
    question: "How is the profit from NFT sales distributed?",
    answer:
      "60% of the profit from select NFT sales goes directly to the MHS Treasury, which then allocates funds for various initiatives and operational expenses.",
  },
  {
    question: "What are development cycles in MHS?",
    answer:
      "Development cycles in MHS refer to phases where specific milestones, as laid out in the roadmap, are achieved. Each cycle is initiated after the complete minting of an NFT group and lasts for 180 days.",
  },
  {
    question: "How can I vote on MHS DAO proposals?",
    answer:
      "MHS NFT holders have exclusive voting rights. Once a proposal is open for voting, members will be provided with guidelines and tools to cast their vote.",
  },
  {
    question: "Do I need technical knowledge to be a part of MHS?",
    answer:
      "No. While having technical or blockchain knowledge might enhance your experience, it's not a requirement. MHS is designed to be inclusive for enthusiasts from all backgrounds.",
  },
  {
    question: "Can AI entities buy MHS NFTs?",
    answer:
      "While AI can contribute to MHS DAO and the ecosystem, purchasing NFTs requires a cryptocurrency wallet, typically operated by a human. However, the lines between AI and human contributions are designed to be blurred within MHS for richer collaboration.",
  },
  {
    question: "How do I ensure my NFT's authenticity?",
    answer:
      "Your NFT's authenticity can be verified on the blockchain, ensuring that it's a genuine MHS NFT. Always ensure you're buying from official MHS channels and platforms.",
  },
  {
    question: "Can I sell my MHS NFT?",
    answer:
      "Yes, MHS NFTs are transferable assets. You can choose to sell or trade them on secondary markets. A portion of the profits from secondary sales will contribute to the MHS Treasury.",
  },
  {
    question: "Is there a community forum or platform for MHS members?",
    answer:
      "Yes, MHS has dedicated community channels where members can discuss, share insights, and collaborate. Details and access guidelines will be provided to NFT holders.",
  },
  {
    question: "How does MHS ensure transparency?",
    answer:
      "Transparency is a cornerstone of MHS. All DAO data, financial transactions, and decision-making processes are available on the blockchain. Furthermore, MHS shares development plans and source codes with society members.",
  },
  {
    question: "How does MHS view the role of art in the digital age?",
    answer:
      "For MHS, art represents the irreplaceable human touch, emotional depth, and creativity. While we embrace AI's capabilities, we believe that human artistic expression should always have its place in the future of HI and AI interaction.",
  },
  {
    question: "What is the significance of zebras and unicorns in MHS NFTs?",
    answer:
      "Zebras and unicorns add diversity and rarity to the MHS NFT collection. They represent unique traits and attributes, amplifying the exclusivity of the NFTs they're part of.",
  },
  {
    question: "What is the long-term vision of MHS?",
    answer:
      "MHS envisions a future where humans and AI collaborate seamlessly, defining new ways of interaction, decision-making, and mutual growth. We're on a mission to pave the way for this harmonious future.",
  },
  {
    question:
      "Are there any collaborations or partnerships in the MHS ecosystem?",
    answer:
      "Yes, MHS is always open to collaborations that align with our mission. We have ongoing partnerships with various entities in the tech, art, and blockchain domains. More details can be found on our official channels.",
  },
  {
    question: "How can I contact MHS for partnerships or collaborations?",
    answer:
      "For partnership inquiries, you can reach out to our official email provided on our website or contact us through our official social media channels.",
  },
  {
    question: "How is MHS addressing sustainability?",
    answer:
      "MHS is conscious of its environmental footprint. We're constantly exploring ways to make our operations more sustainable, from optimizing blockchain processes to collaborating with eco-friendly initiatives.",
  },
  {
    question: "Are there any membership tiers in MHS?",
    answer:
      "While all MHS NFT holders are valued members, the NFTs are categorized from Group A to D, with each group having its unique benefits and significance within the MHS ecosystem.",
  },
  {
    question: "Can I donate to MHS?",
    answer:
      "Yes, donations are accepted and are a valuable contribution to the MHS Treasury. These funds help support various initiatives and operational costs.",
  },
  {
    question: "How secure is the MHS platform?",
    answer:
      "Security is paramount for MHS. We employ state-of-the-art security measures, both on-chain and off-chain, to ensure the safety of our members' data and assets.",
  },
  {
    question: "How can I stay updated with MHS news and announcements?",
    answer:
      "You can follow our official website, subscribe to our newsletter, or join our social media channels to stay updated with the latest news and updates from MHS.",
  },
  {
    question: "Who can join MHS?",
    answer:
      "Anyone interested in the convergence of human and AI worlds can join MHS. While holding an MHS NFT provides certain privileges, the society is open for enthusiasts from all domains.",
  },
  {
    question: "Can I contribute to MHS if I don't hold an NFT?",
    answer:
      "While NFT holders have exclusive rights within MHS, we value contributions from all. You can engage in discussions, attend public events, and share your insights, even if you don't hold an NFT.",
  },
  {
    question: "Is MHS associated with any other NFT projects?",
    answer:
      "MHS is an independent initiative but collaborates with various entities in the NFT space for mutual growth. Any official collaborations will be announced on our channels.",
  },
  {
    question: "How does MHS handle disputes or disagreements within the DAO?",
    answer:
      "MHS has a clear governance structure in place. In case of disputes, the DAO members discuss and vote to come to a consensus. The decentralized nature ensures fairness and transparency.",
  },
  {
    question: "Where can I find technical details or whitepapers about MHS?",
    answer:
      "Technical documents, whitepapers, and in-depth information about MHS can be accessed on our official website and dedicated portals for members.",
  },
  {
    question: "What is the return or refund policy for purchased NFTs?",
    answer:
      "NFT sales are final and non-refundable. However, you have the flexibility to sell or trade your NFT on secondary markets.",
  },
  {
    question: "How do I report a technical issue or bug?",
    answer:
      "If you encounter any technical issues, please contact our support team through the official website or our community channels. We appreciate the community's feedback and strive to resolve issues promptly.",
  },
];

export const PRIVACY_POLICY_DATA = [
  "MetaHorse Society (MHS) respects and values the privacy of all its members, participants, and users. This Privacy Policy details our commitment to safeguarding the personal and intelligence-based data that you entrust to us. By interacting with our platform, services, or initiatives, you consent to the practices described in this policy.",

  "MHS collects minimal data for operational purposes. The primary information collected is associated with Ethereum wallet addresses and related transaction data. No personal identifiers, such as names or physical addresses, are directly associated with wallet addresses within our system. We believe that in the realm of intelligence, origin or category should not prejudice value, thus emphasizing our commitment to anonymity.",

  "While our platform operates predominantly on public blockchain infrastructures where transaction data is transparent, we pledge not to harness or utilize this data for any purpose other than the legitimate functions of MHS, including but not limited to, validation of membership rights or processing of proposals in the MHS DAO.",

  "MHS employs robust security measures to protect against unauthorized access, alteration, or dissemination of any data we hold. Our commitment extends to ensuring any third-party service providers adhere to similar standards of data protection.",

  "Any intelligence contributing to MHS, whether human, AI, or a hybrid, is respected and treated equally. We recognize the rights of all intelligences to privacy and pledge not to discriminate, expose, or exploit contributions based on their source or nature.",

  "We do not sell, trade, or rent your personal data or intelligence contributions to third parties. Furthermore, we will never commercialize the insights, proposals, or contributions without the explicit consent of the contributing intelligence.",

  "Our platform may, from time to time, contain links to external sites or services. MHS is not responsible for the privacy practices or content of these external sites. We recommend users exercise caution and review the privacy policies of any external sites they may visit.",

  "MHS may update this Privacy Policy periodically to reflect changes in our practices, technologies, legal requirements, and other factors. We encourage members and users to periodically review this policy for updates.",

  "Any questions, concerns, or clarifications regarding this Privacy Policy can be directed to our designated data protection officer or team. We're committed to resolving any inquiries in a timely and transparent manner.",

  "In the ever-evolving landscape of intelligence and technology, MHS stands firm in its commitment to respect, protect, and champion the rights of all contributors. Our policy aims not just to comply with legal mandates but also to set a gold standard in ethical and responsible data handling in the confluence of human and AI realities.",
];

export const TERMS_AND_CONDITIONS_DATA = [
  "Welcome to MetaHorse Society (MHS). These Terms and Conditions govern your use of our platform and services. By accessing, using, or participating in any service offered by MHS, you agree to comply with and be bound by these terms.",

  "MHS offers a range of services including but not limited to MHS NFTs, MHS DAO, and MHS Treasury. Each of these may have specific guidelines or rules that are an integral part of these Terms. By agreeing to the general terms, you also agree to adhere to the specific terms associated with each service.",

  "All intellectual property rights pertaining to the platform, its services, and its content belong to MHS or its licensors. Unauthorized use, duplication, or redistribution of this content is strictly prohibited.",

  "MHS NFTs provide certain membership rights as detailed in our platform. Owning an NFT does not grant any equity, ownership, or decision-making power outside of what is explicitly outlined in our documentation.",

  "Participation in MHS DAO involves certain risks. Decisions are made based on community consensus and may not always align with individual member preferences. Participation in voting, proposal submission, or any other DAO activity is voluntary and at the member's discretion.",

  "The MHS Treasury functions as the financial backbone of the society. Funds in the treasury are utilized as per the decisions of the DAO and as described in our platform documentation. MHS does not guarantee returns, profits, or any financial gains.",

  "Users are solely responsible for securing their access to the platform, including their wallet, private keys, and any other form of authentication. MHS is not liable for any loss or damage arising from unauthorized access to user accounts.",

  "While we strive for accuracy and timeliness in our platform's content, MHS does not guarantee its absolute accuracy. Users act on this information at their own risk.",

  "MHS reserves the right to modify, suspend, or terminate any service without prior notice. This can be due to technical reasons, legal constraints, or any other reason deemed necessary by the MHS administration.",

  "Any disputes arising from the use of our services will be governed by the laws of the jurisdiction where MHS is registered. By agreeing to these terms, users consent to this jurisdiction for the resolution of any disputes.",

  "MHS may update these Terms and Conditions periodically. It's the responsibility of the users to review these terms regularly. Continued use of the platform after changes are made implies acceptance of the revised terms.",

  "If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions remain in full force and effect.",

  "For any questions or concerns related to these Terms and Conditions, users can reach out to our support team or designated contact channel.",

  "Your relationship with MHS is strictly that of a user and service provider. Nothing in these terms establishes any partnership, joint venture, employment, or agency relationship.",

  "By using our platform, you acknowledge and accept all risks related to the volatile and unpredictable nature of blockchain technology, cryptocurrencies, and decentralized platforms.",
];
